<template>
  <div>
    <Title />
        <div class="app-content-box bg-gray">
      <div class="container pt-4">
        <div class="top-heading-big text-center">Wybierz rodzaj planu czytelniczego</div>
      </div>
      <div class="tab-pane-slider swiper-container circle-icon-slider">
        <swiper ref="mySwiper" :options="swiperOptions" @slideChange="changeSwiperIndex" @ready="changeSwiperIndex" >
          <div class="people-slider-avatar growing-slide swiper-slide slide-wrapper" v-for="(el, key) in planOptions" :key="key">
            <swiper-slide>
                <div class="growing-slide-avatar-container books-featured-slide-avatar-container d-flex justify-content-center">
                  <div class="growing-slide-avatar bottom-add-sign">
                  <router-link :to="el.url">
                    <div class="text-center text-dark">
                      <div class="circle-icon">
                        <img :src="el.img" alt="" style="width: 100%; height: 100%;">
                      </div>
                      <div class="book-heading-small">plan czytelniczy</div>
                      <div :class="key !== index ? 'opacity-0' : 'negative-margins'" class="book-heading-big">{{el.title}}</div>
                    </div>
                  </router-link>
                  </div>
                </div>
            </swiper-slide>
          </div>

        </swiper>
      </div>
      <div class="btn-wrapper">
        <div class="container description text-center">
          {{ this.currentOption['subtitle'] }}
        </div>
        <div class="container">
          <router-link to="/edytuj-plan-stronnicowy" v-if="index === 0">
            <a href="#" class="btn-black-full w-100">Wybierz</a>
          </router-link>
          <router-link to="/edytuj-plan-czasu" v-if="index === 1">
            <a href="#" class="btn-black-full w-100">Wybierz</a>
          </router-link>
          <router-link to="/edytuj-plan-ksiazkowy" v-if="index === 2">
            <a href="#" class="btn-black-full w-100">Wybierz</a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../components/Title'
export default {
  name: 'TrainingPlanChoose',
  components: {
    Title
  },
  data () {
    return {
      trainingType: '',
      index: '',
      types: ['stron', 'czasu', 'książkowy'],
      currentOption: 1,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 32,
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false
        }
      },
      planOptions: [
        {
          img: require('../assets/images/plans/page_plan.svg'),
          title: 'według liczby stron',
          subtitle: 'Wybierając ten plan czytelniczy, możesz śledzić swoje postępy za pomocą liczby przeczytanych stron.',
          url: '/edytuj-plan-stronnicowy'
        },
        {
          img: require('../assets/images/plans/time_plan.svg'),
          title: 'według ilości czasu',
          subtitle: 'Wybierając ten plan czytelniczy, możesz śledzić swoje postępy za pomocą ilości czasu, który poświęciłeś na czytanie.',
          url: '/edytuj-plan-czasu'
        },
        {
          img: require('../assets/images/plans/book_plan.svg'),
          title: 'według liczby książek',
          subtitle: 'Wybierając ten plan czytelniczy, możesz śledzić swoje postępy za pomocą liczby przeczytanych książek.',
          url: '/edytuj-plan-ksiazkowy'
        }
      ]
    }
  },
  methods: {
    changeSwiperIndex () {
      this.index = this.$refs.mySwiper.$swiper.activeIndex
      this.trainingType = this.types[this.index]
      this.currentOption = this.planOptions[this.index]
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
    padding-bottom: 70px;
  }
  .circle-icon {
    svg {
      font-size: 64px;
    }
  }

  .btn-wrapper {
    width: 100%;
  }

  .growing-slide-avatar-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;

    &.books-featured-slide-avatar-container {
      padding-bottom: 25px;
    }

    img {
      width: 100%;
    }
  }

  .description {
    margin: 10px auto 50px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    padding: 0 5px;

    @media(max-width: 320px){
      margin-bottom: 10px;
    }
  }

  .opacity-0{
    opacity: 0;
  }

  .negative-margins{
    position: absolute;
    left: -70px;
    right: -70px;
  }

  .swiper-container{
      height: 250px !important;
  }
</style>
