<template>
  <div class="top-menu-visible top-menu-dark">
    <div class="app-title">
      <span class="app-title-text">Bookme</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Title'

}
</script>
<style lang="scss" scoped></style>
